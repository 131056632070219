@charset 'utf-8'
// 字型定義
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&family=Roboto&family=Noto+Serif+TC:wght@400;500;600&family=Noto+Sans:wght@300;400;500;700&display=swap")
@font-face
    font-family: 'BMWType'
    src: url(~@/assets/fonts/BMWTypeNextLatinTT-Regular.ttf)
    font-weight: normal
@font-face
    font-family: 'BMWType'
    src: url(~@/assets/fonts/BMWTypeNextLatinTT-Light.ttf)
    font-weight: lighter
@font-face
    font-family: 'BMWType'
    src: url(~@/assets/fonts/BMWTypeNextLatinTT-Thin.ttf)
    font-weight: 100
@font-face
    font-family: 'BMWType'
    src: url(~@/assets/fonts/BMWTypeNextLatinTT-Bold.ttf)
    font-weight: bold

// loading bar
$loading_bgc: #12602e
$loading_boxC: #c5a86f
// 字體設定
$base_font: "bmwType","文鼎UD晶熙黑體", "microsoft jhenghei",'Noto Sans TC', Helvetica, Noto Sans, sans-serif

// 版寬
$wrapper_width: 1920px

// 左右留白(PC)
$container_space: 30px

// container max-width
$container_width: 750px
$container_width_final: $container_width - $container_space*2

// 文字顏色
$dark_blue: #0470bd
$hover_blue: #0a53b6
$dark_gray: #333333
$border_gray: #666666
$light_gray: #d8d8d8
$bg_gray: #f8f8f8
$dark_red: #c43535

//動畫
// @keyframes dot
//     0% 
//         transform: translateY(0)
//     25% 
//         transform: translateY(-10px)
//     75% 
//         transform: translateY(10px)
//     100% 
//         transform: translateY(0)

@keyframes aniDot
    0%
        transform: translateY(0)
    25%
        transform: translateY(-10px)
    75%
        transform: translateY(10px)
    100%
        transform: translateY(0)
