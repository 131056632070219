@import "@/assets/sass/_variable.sass"
@import "@/assets/sass/_mixin.sass"
.lightbox
	.container
		.content-bg
			padding: 3.15em 5.1em
			max-width: 1400px
			background-color: #ffffff
			@include screen(768px)
				width: 19.4em
				padding: 1em 0em
			.content
				height: 45vh
				max-height: 730px
				padding-right: 3.3em
				@include screen(768px)
					height: 60vh
					padding-right: .92em
				.row-container
					border-bottom: 1px solid #888888
					margin-bottom: 1.75em
					padding-bottom: 1.4em
					.row
						&.question
							display: flex
							align-items: center
							@include screen(768px)
								align-items: flex-start
							span
								font-size: 1.3em
								font-weight: bold
								line-height: normal
								letter-spacing: normal
								width: 1.95em
								flex-shrink: 0
								@include screen(768px)
									width: 2em
							p
								font-size: .9em
								font-weight: bold
								line-height: 1.5
								letter-spacing: .015em
						&.answer
							font-size: .9em
							font-weight: lighter
							color: #333
							line-height: 1.5
							letter-spacing: .015em
							margin-left: 2.83em
