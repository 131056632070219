@import "@/assets/sass/_variable.sass"
@import "@/assets/sass/_mixin.sass"
.loading
	background-color: #fff
	width: 100%
	height: 100%
	position: fixed
	top: 0
	left: 0
	z-index: 10000
	.container
		width: 100%
		height: 100%
		align-items: center
		justify-content: center
		display: flex
		.dot
			display: block
			width: .7em
			height: .7em
			border-radius: 50%
			background-color: $dark_blue
			margin: 0 5px
			@include screen(768px)
				width: .5em
				height: .5em
			&:nth-of-type(1)
				animation: aniDot 1s infinite linear
			&:nth-of-type(2)
				animation: aniDot 1s infinite linear
				animation-delay: .2s
			&:nth-of-type(3)
				animation: aniDot 1s infinite linear
				animation-delay: .4s
			&:nth-of-type(4)
				animation: aniDot 1s infinite linear
				animation-delay: .6s
			&:nth-of-type(5)
				animation: aniDot 1s infinite linear
				animation-delay: .8s
			&:nth-of-type(6)
				animation: aniDot 1s infinite linear
				animation-delay: 1s
			&:nth-of-type(7)
				animation: aniDot 1s infinite linear
				animation-delay: 1.2s
