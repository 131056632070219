@import "@/assets/sass/_variable.sass"
@import "@/assets/sass/_mixin.sass"

header
	@include bg("@/assets/images/bg_card.png",cover)
	margin-bottom: 1em
	@include screen(1199px)
		margin-bottom: 0.7em
	&.fixed 
		position: fixed
		width: 100%
		top: 0
		z-index: 9999
		max-width: 1920px
	&.bg
		position: fixed
		top: 0
		left: 50%
		transform: translateX(-50%)
		width: 100%
		box-sizing: border-box
		z-index: 9999
		background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.45) 18%, rgba(0, 0, 0, 0))
		pointer-events: auto
	.container
		display: flex
		justify-content: space-between
		max-width: $wrapper_width
		margin: auto
		position: relative
		.logo
			width: 18.3em
			@include screen(1440px)
				width: 15.55em
			@include screen(1199px)
				width: 13.5em
				transform: translate(-4%,-8%)
		ul
			li
				a
					display: flex
					justify-content: center
					position: relative
					.count
						font-size: .6em
						line-height: 1.33
						color: #fff
						background-color: $dark_red
						width: 1.66em
						height: 1.66em
						border-radius: 9999px
						display: flex
						justify-content: center
						align-items: center
						transform: translate(.25em,-.65em)
		&.mo
			display: none
			@include screen(1199px)
				display: flex
				flex-direction: column
				align-items: center
				justify-content: center
				position: fixed
				top: 0
				left: 0
				transform: translateX(100%)
				z-index: 99
				width: 100%
				height: 100vh
				height: calc(var(--vh, 1vh) * 100)
				background: none
				background-color: #efefef
				transition: .3s
				&.active
					transform: translateX(0%)
					transition: .3s
					z-index: 999
			a.btn-close
				width: 1.05em
				height: 1.05em
				position: fixed
				top: 1.5em
				right: 1.5em
				@include bg("@/assets/images/ic_close.svg",contain)
			ul
				text-align: center
				@include screen(1199px)
					display: block
				li
					width: 12.8em
					border-bottom: 2px solid #bababa
					padding: 1em 0
					a
						color: $dark_gray
						font-size: 1em
						line-height: 1.5
						font-weight: bold
						align-items: center
						span.icon
							width: 0.62em
							height: 0.62em
							margin-left: .5em
							@include bg("@/assets/images/ic_arrow-right-black.svg",contain)
		&.pc
			a.btn-menu
				position: absolute
				right: 1em
				top: 1.9em
				height: 1.35em
				width: 1.35em
				z-index: 5
				display: none
				@include screen(1199px)
					display: block
				span
					display: block
					width: 100%
					height: 2.5px
					background-color: #fff
					border-radius: .04em
					margin: .21em auto
			ul
				display: flex
				justify-content: center
				align-items: center
				margin-right: 1.75em
				@include screen(1440px)
					margin-right: .5em
				@include screen(1199px)
					display: none
				li
					a
						color: #fff
						font-size: .9em
						line-height: 1.11
						letter-spacing: .04em
						margin-right: 3.5em
						@include screen(1440px)
							font-size: .8em
							margin-right: 3em
						&::before
							content: ''
							width: 0%
							height: 1px
							background-color: #fff
							position: absolute
							bottom: -.05em
							left: 0
							transition: .15s
						&::after
							content: ''
							width: 0.7em
							height: 0.7em
							@include bg("@/assets/images/ic_arrow-right-white.svg",contain)
							position: absolute
							top: 50%
							right: -1.1em
							transform: translateY(-50%)
							transition: .3s
						&:hover
							&::before
								width: 100%
							&::after
								right: -1.3em
