html
    scroll-behavior: smooth
    
html,body
    font-size: 100px
    font-family: $base_font
    overflow-x: hidden
    #__vconsole
        position: relative
        z-index: 1000000

#app
    position: relative
    font-size: .2rem
    max-width: $wrapper_width
    margin: auto
    @include screen(768px)
        font-size: 4.67vw

@media print
    *
        -webkit-print-color-adjust: exact !important
        font-size: 10px !important
    .noPrint
        display: none !important

br
    &.mo
        display: none
        @include screen(768px)
            display: block
    &.pc
        display: block
        @include screen(768px)
            display: none

.fade-enter-active,.fade-leave-active
    transition: .5s

.fade-enter,.fade-leave-to
    opacity: 0

.fade-enter-to,.fade-leave
    opacity: 1

.fadeLoading-enter-active
    transition: 0

.fadeLoading-leave-active
    transition: .5s

.fadeLoading-enter,.fadeLoading-leave-to
    opacity: 0

.fadeLoading-enter-to,.fadeLoading-leave
    opacity: 1

a.btn-collect
    width: 2em
    height: 2em
    border-radius: 99999px
    @include bg("@/assets/images/ic_bookmark-black.svg",.56em .76em)
    background-color: #fff
    transition: .3s
    box-shadow: .25em .25em .55em 0 rgba(0, 0, 0, 0.16)
    &.active
        @include bg("@/assets/images/ic_bookmark-white.svg",.56em .76em)
        background-color: $dark_blue
        transition: .3s

.btn-box
    a
        font-size: .9em
        font-weight: bold
        line-height: 1.11
        letter-spacing: .06em
        border-radius: .27em
        height: 3.88em
        display: flex
        align-items: center
        justify-content: center
        transition: .3s
        &.lock
            opacity: .5
            pointer-events: none
            transition: .3s
        &.btn-blue
            background-color: $dark_blue
            color: #fff
            transition: .3s
            &:hover
                background-color: $hover_blue
                transition: .3s
        &.btn-white
            background-color: #fff
            color: $dark_blue

.popup
    width: 100%
    height: 100vh
    height: calc(var(--vh, 1vh) * 100)
    left: 0
    right: 0
    background-color: $light_gray
    position: fixed
    top: 0
    z-index: 1000
    transition: .5s
    @include screen(768px)
        background-color: #fff
    .container
        position: relative
        a.btn-close
            width: 1.5em
            height: 1.5em
            position: absolute
            top: 2.5em
            right: 2.5em
            z-index: 5
            @include bg("@/assets/images/ic_close.svg",contain)
            @include screen(768px)
                width: 1.05em
                height: 1.05em
                top: 1.5em
                right: 1.5em
        .btn-box
            a
                background-color: $dark_blue
                color: #fff

.lightbox
    width: 100%
    height: 100vh
    height: calc(var(--vh, 1vh) * 100)
    left: 0
    right: 0
    background-color: $light_gray
    position: fixed
    top: 0
    z-index: 10000
    transform: translateX(100%)
    transition: .5s
    @include screen(768px)
        background-color: #fff
    &.active
        transform: translateX(0%)
        transition: .3s
    .container
        max-width: $wrapper_width
        margin: auto
        height: 100%
        position: relative
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        a.btn-close
            width: 1.5em
            height: 1.5em
            position: absolute
            top: 2.5em
            right: 2.5em
            z-index: 5
            @include bg("@/assets/images/ic_close.svg",contain)
            @include screen(768px)
                width: 1.05em
                height: 1.05em
                top: 1.5em
                right: 1.5em
        >h3
            font-size: 1.1em
            color: $dark_gray
            text-align: center
            font-weight: bold
            line-height: 1.36
            margin-bottom: 1.81em
            @include screen(768px)
                margin-bottom: 0.9em
        .content-bg
            width: 100%
            border-radius: .25em
            box-sizing: border-box
            h2
                font-size: 1.9em
                line-height: 1.47
                letter-spacing: normal
                margin-bottom: 1.62em
                padding-left: .6em
                position: relative
                @include screen(768px)
                    margin-bottom: .289em
                &::before
                    content: ''
                    width: 0.21em
                    height: .85em
                    background-color: $dark_blue
                    position: absolute
                    top: 50%
                    left: 0em
                    transform: translateY(-50%)
            .content
                overflow-y: scroll
                position: relative
                &::-webkit-scrollbar-thumb
                    width: .35em
                    background-color: $dark_blue
                &::-webkit-scrollbar
                    width: .35em
                    background-color: $light_gray
        .btn-box
            a
                width: 14.44em
                background-color: $dark_blue
                color: #fff
                @include screen(768px)
                    width: 21.55em

.assistant,.sale,.search,.comparison,.collection,.bebenefits
    position: relative
    top: -0.25em
    background-color: $bg_gray
    @include screen(768px)
        background-color: #fff
    &::before
        content: ''
        width: 100%
        height: 1em
        border-top-right-radius: .4em
        border-top-left-radius: .4em
        background-color: $bg_gray
        position: absolute
        top: -1em
        left: 0
        z-index: 99
        @include screen(768px)
            background-color: #fff
    .container
        padding-top: 2em
        padding-bottom: 2.5em
        width: 100%
        max-width: 1400px
        margin: auto
        position: relative
        @include screen(1400px)
            width: 80%
        @include screen(768px)
            width: 100%
            padding: 0em 1em 4em 1em
            box-sizing: border-box
        >.title
            margin-bottom: 1.9em
            h2
                color: $dark_gray
                font-size: 1.9em
                line-height: 1.47
                padding-left: .6em
                position: relative
                @include screen(768px)
                    line-height: normal
                    margin-bottom: .26em
                &::before
                    content: ''
                    width: 0.21em
                    height: .95em
                    background-color: $dark_blue
                    position: absolute
                    left: 0
                    top: 50%
                    transform: translateY(-50%)
            p
                font-size: 1em
                color: #393939
                line-height: 1.45
                letter-spacing: normal
                margin-left: 1.15em
                @include screen(996px)
                    margin-left: 0
                @include screen(768px)
                    margin-bottom: .5em

.assistant,.sale,.search,.testdrive,.reserve
    .container
        .form-box
            background-color: #fff
            border-radius: .25em
            padding-top: 3.75em
            padding-bottom: 7.85em
            position: relative
            @include screen(768px)
                padding-top: 1.5em
                padding-bottom: 0
            >p
                font-size: .8em
                color: $dark_gray
                line-height: 1.38
                letter-spacing: normal
                position: absolute
                right: 0
                top: -4.125em
                @include screen(996px)
                    left: 0
                    right: auto
                    top: -2em
                span
                    color: $dark_blue
            .row-box
                max-width: 1130px
                width: 100%
                margin: auto
                display: flex
                justify-content: space-between
                @include screen(1400px)
                    padding: 0 6em
                    box-sizing: border-box
                @include screen(1200px)
                    padding: 0 2em
                @include screen(996px)
                    padding: 0 6em
                    display: block
                @include screen(768px)
                    width: 100%
                    padding: 0
                &.hide
                    display: none
                &.brand
                    flex-wrap: wrap
                    p
                        width: 100%
                        font-size: .9em
                        line-height: 1.5
                        margin-bottom: 1.22em
                        color: #393939
                &.list .row
                    @include screen(1400px)
                        width: 100%
                .row
                    max-width: 540px
                    width: 100%
                    margin-bottom: 2em
                    @include screen(1400px)
                        width: 45%
                        max-width: 100%
                        margin: 0 1em 2em 1em
                    @include screen(996px)
                        width: 80%
                        margin: 0 auto 2em auto
                    @include screen(768px)
                        width: 100%
                        margin-bottom: 1.5em
                    canvas
                        display: none
                    &.readonly
                        .input-box
                            background-color: #f0f0f0
                            border-color: #f0f0f0
                    &.check
                        max-width: 100%
                        font-size: .9em
                        line-height: 1.57
                        margin-bottom: .85em
                        .check-box
                            width: 100%
                            height: auto
                        .style-check
                            margin-top: .25em
                            align-self: flex-start
                    &.textarea
                        max-width: 1130px
                        @include screen(1400px)
                            width: 100%
                        @include screen(996px)
                            width: 80%
                        @include screen(768px)
                            width: 100%
                    &.manufacture,&.licensed
                        display: flex
                        flex-wrap: wrap
                        justify-content: flex-start
                        .title
                            width: 100%
                        .input-box
                            max-width: 225px
                            position: relative
                            @include screen(768px)
                                width: 40%
                            &::after
                                font-size: .9em
                                color: $dark_gray
                                position: absolute
                                top: 50%
                                transform: translateY(-50%)
                                right: -1.85em
                        .input-box.year
                            margin-right: 2.4em
                            @include screen(1400px)
                                margin-bottom: 1em
                            &::after
                                content: '年'
                        .input-box.month
                            @include screen(1400px)
                                margin-bottom: 1em
                            &::after
                                content: '月'
                    &.milage
                        .input-box
                            max-width: 495px
                            position: relative
                            @include screen(996px)
                                max-width: 346px
                            &::after
                                content: 'KM'
                                font-size: .9em
                                color: $dark_gray
                                position: absolute
                                top: 50%
                                transform: translateY(-50%)
                                right: -2.5em
                    &.address
                        display: flex
                        flex-wrap: wrap
                        justify-content: space-between
                        @include screen(768px)
                            margin-bottom: 0em
                        .title
                            width: 100%
                        .select-box
                            max-width: 245px
                            @include screen(1400px)
                                max-width: 100%
                                width: 45%
                            @include screen(996px)
                                width: 45%
                            @include screen(768px)
                                width: 100%
                                margin-bottom: 1em
                        &~.row
                            align-self: flex-end
                            .title
                                @include screen(768px)
                                    margin-bottom: 0
                    &.contact,&.upload,&.pm
                        display: flex
                        flex-wrap: wrap
                        justify-content: flex-start
                        .title
                            width: 100%
                    &.pm
                        .radio-box
                            label
                                flex-shrink: 0
                            &:nth-of-type(1)
                                width: 75%
                                margin-right: 1em
                                .input-box
                                    margin-left: .5em
                                    max-width: 245px
                                    input
                                        font-size: .8em
                                        height: 100%
                                        width: 100%
                            &:nth-of-type(2)
                                width: auto
                    &.contact
                        .check-box
                            @include screen(1400px)
                                width: 100%
                            @include screen(996px)
                                width: 50%
                        &~.contact
                            .check-box
                                @include screen(768px)
                                    width: 100%
                    &.upload~.row
                        @include screen(996px)
                            margin-bottom: 6em
                        @include screen(768px)
                            margin-bottom: 5em
                    .title
                        font-size: .9em
                        color: $dark_gray
                        letter-spacing: normal
                        margin-bottom: .83em
                        span
                            color: $dark_blue
                    .input-box,.select-box
                        width: 100%
                        max-width: 540px
                        height: 3em
                        border-radius: .2em
                        border: 1px solid $border_gray
                        color: $dark_gray
                        box-sizing: border-box
                        @include screen(768px)
                            max-width: 100%
                        input,select
                            font-size: .8em
                            color: $dark_gray
                            width: 100%
                            height: 100%
                            padding-left: 1.25em
                            box-sizing: border-box
                            &::-webkit-outer-spin-button,
                            &::-webkit-inner-spin-button
                                -webkit-appearance: none
                                margin: 0
                    .select-box
                        position: relative
                        &::before
                            content: ''
                            width: .8em
                            height: .8em
                            position: absolute
                            top: 50%
                            right: 1em
                            transform: translateY(-50%)
                            @include bg("@/assets/images/ic_arrow-down-gray.svg",contain)
                            pointer-events: none
                        select
                            cursor: pointer
                    .check-box,.radio-box
                        width: 42.5%
                        height: 3em
                        display: flex
                        align-items: center
                        justify-content: flex-start
                        input,label
                            font-size: .9em
                        a
                            color: $dark_blue
                            text-decoration: underline
                            display: inline-block
                        &.unchecked
                            .style-check
                                border-color: #c4c1c1
                        .style-check,.style-radio
                            height: .8em
                            width: .8em
                            border-radius: .166em
                            border: 1px solid $dark_gray
                            margin-right: 0.55em
                            position: relative
                            flex-shrink: 0
                            &:before
                                content: ''
                                height: 0.3em
                                width: 0.5em
                                border: .1em solid transparent
                                border-top: none
                                border-right: none
                                position: absolute
                                background-color: transparent
                                top: 50%
                                left: 50%
                                transform: translate(-50%,-70%) rotate(-45deg)
                        input:checked +.style-check::before,
                        input:checked +.style-radio::before
                            border-color: $dark_gray
                        .text-check
                            font-size: .9em
                            color: $dark_gray
                    .check-box,.radio-box
                        input
                            height: 0
                            width: 0
                    .radio-box
                        @include screen(768px)
                            width: auto
                            min-width: 45%
                            flex-shrink: 0
                        label
                            font-size: .9em
                            color: $dark_gray
                    .upload-box
                        font-size: .9em
                        color: $dark_gray
                        position: relative
                        height: 3em
                        &::after
                            content: '*請上傳愛車車輛清楚外觀、內裝以及汽車行照，我們將為您盡速提供鑑價服務。\a*僅限上傳 jpg 格式照片，檔案大小 3 MB 以內'
                            white-space: break-spaces
                            line-height: 1.43
                            color: #333333
                            font-size: .77em
                            position: absolute
                            left: 0
                            top: 4.25em
                        input
                            padding-left: 2.8em
                            line-height: 3em
                            width: 100%
                            box-sizing: border-box
                            @include screen(768px)
                                padding-left: 2.9em
                        a.btn-upload
                            color: #fff
                            font-size: 1em
                            text-align: center
                            background-color: #393939
                            width: 7.77em
                            height: 2.22em
                            line-height: 2.22em
                            border-radius: .16em
                            padding-right: .875em
                            box-sizing: border-box
                            position: absolute
                            left: 0
                            top: 50%
                            transform: translateY(-50%)
                            pointer-events: none
                            &::before
                                content: ''
                                width: 0.84em
                                height: 0.84em
                                position: absolute
                                top: 50%
                                right: 1em
                                transform: translateY(-50%)
                                @include bg("@/assets/images/ic_upload.svg",contain)
                    .textarea-box
                        box-sizing: border-box
                        textarea
                            width: 100%
                            height: 7.5em
                            box-sizing: border-box
                            outline: none
                            border: 1px solid $border_gray
                            border-radius: .22em
                            resize: none
                            font-size: .8em
                            padding: 1em
            .change-box
                background-color: #f8f8f8
                border-radius: .15em
                max-width: 1130px
                width: 100%
                margin: auto
                padding: 2.5em 2.4em .5em 2.4em
                margin-bottom: 2em
                box-sizing: border-box
                overflow-x: hidden
                @include screen(1400px)
                    max-width: 100%
                    width: 80%
                @include screen(1200px)
                    width: 88%
                @include screen(996px)
                    width: 55%
                @include screen(768px)
                    width: 100%
                    padding: 2.5em 1em 1.5em 1em
                h3
                    font-size: .9em
                    font-weight: bold
                    line-height: 1.44
                    margin-bottom: 3.33em
                    @include screen(768px)
                        margin-bottom: 2.22em
                .row-box
                    padding: 0
                    .row
                        max-width: 500px
                        @include screen(1400px)
                            max-width: 100%
                            width: 45%
                        @include screen(996px)
                            width: 80%
                        @include screen(768px)
                            width: 100%
                        &.textarea
                            max-width: 1130px
                            @include screen(1400px)
                                width: 100%
                            @include screen(996px)
                                width: 80%
                            @include screen(768px)
                                width: 100%
            .btn-box
                margin-top: 2.5em
                @include screen(768px)
                    margin-top: 3em
                a.btn-send
                    width: 13.33em
                    margin: auto
                    @include screen(768px)
                        width: 21.88em
