@import "@/assets/sass/_variable.sass"
@import "@/assets/sass/_mixin.sass"
.lightbox
	.container
		.content-bg
			max-width: 1164px
			padding: 1em 1.35em
			background-color: #f5f2f2
			margin-bottom: 2.5em
			@include screen(768px)
				width: 19.4em
				padding: 1em .5em
				margin-bottom: 1em
			.content
				height: 41vh
				max-height: 440px
				padding-right: 2em
				color: $dark_gray
				@include screen(768px)
					height: 50vh
					padding-right: .92em
				p.title
					font-size: .9em
					font-weight: bold
					line-height: 1.67
					letter-spacing: normal
					margin-bottom: .83em
				ul
					font-size: .7em
					color: $dark_gray
					margin-bottom: 1em
					margin-left: 1em
					li
						display: flex
						line-height: 1.43
						margin-bottom: .357em
						letter-spacing: normal
						span
							color: #C01216
							font-weight: bold
							text-decoration: underline
						p
							&:nth-of-type(1)
								width: 2.5em
								flex-shrink: 0
						a
							display: inline-block
							color: $dark_blue
							text-decoration: underline
				p.mark
					font-size: .7em
					color: $dark_gray
					line-height: 1.43
