@import "@/assets/sass/_variable.sass"
@import "@/assets/sass/_mixin.sass"
.lightbox
	.container
		.content-bg
			max-width: 1164px
			padding: 1em 1.35em
			background-color: #f5f2f2
			margin-bottom: 2.5em
			@include screen(768px)
				width: 19.4em
				padding: 1em .5em
				margin-bottom: 1em
			.content
				height: 41vh
				max-height: 440px
				padding-right: 2em
				color: $dark_gray
				@include screen(768px)
					height: 50vh
					padding-right: .92em
				.txt
					font-size: .7em
					line-height: 1.43
					margin-bottom: 2em
					letter-spacing: normal
				ul
					font-size: .7em
					line-height: 1.43
					li
						margin-bottom: 1.25em
					ul
						font-size: 1em
						li
							margin-bottom: .25em
