@charset "utf-8"
//------------------------- media queries -------------------------//

//media-queries的最大寬度
@mixin screen($res-max)
	@media screen and (max-width: $res-max)
		@content

//media-queries的最小寬度
@mixin screen_min($res-min)
	@media screen and (min-width: $res-min)
		@content

//media-queries的最小最大寬度
@mixin screen_and($res-min, $res-max)
	@media screen and (min-width: $res-min) and (max-width: $res-max)
		@content

//------------------------- animation的keyframes -------------------------//
@mixin keyframes($name) 
	@keyframes #{$name} 
		@content

//------------------------- px轉em -------------------------//

@function pxToEm($px, $base)
	@return ($px / $base) * 2em

//------------------------- 限定行數  行高 行數(行高x行數)x1em ---------//

@mixin clamp($lh, $clamp) 
	line-height: $lh
	display: -webkit-box
	/* 第幾行要顯示點點點 */
	-webkit-box-orient: vertical
	-webkit-line-clamp: $clamp
	overflow: hidden

//------------------------- 浮動置中 -------------------------//

@mixin position($position_x, $position_y, $coordinate_x, $coordinate_y) 
	position: absolute
	top: $position_x
	left: $position_y
	transform: translate($coordinate_x, $coordinate_y)

//------------------------- calc -------------------------//

@mixin calc($temp_width,$wid) 
	width: calc($temp_width - $wid)

//------------------------- 不規則圓角 左上 右上 右下 左下-------------------------//

@mixin border_show($border_topleft, $border_topright, $bottom_right, $border_bottomleft) 
	border-top-left-radius: $border_topleft
	border-top-right-radius: $border_topright
	border-bottom-right-radius: $bottom_right
	border-bottom-left-radius: $border_bottomleft

//------------------------- triangle 三角形 方向 尺寸 顏色 -------------------------//
@mixin triangle_specially($direction, $height, $width, $borderColor)
	width: 0
	height: 0
	border-style: solid 

	@if $direction==left_top
		//左上
		border-width: $height $width 0 0
		border-color: $borderColor transparent transparent transparent
	@else if $direction==left_l
		// 左
		border-width: $width $height $width 0
		border-color: transparent $borderColor transparent transparent
	@else if $direction==right_r 
		//右
		border-width: $height 0 $height $width
		border-color: transparent transparent transparent $borderColor
	

	@else if $direction==bottom_a 
		//下
		border-width: $height $width 0 $width
		border-color: $borderColor transparent transparent transparent

//------背景
@mixin bg($url,$size) 
	background: url($url) no-repeat center / $size