@import "@/assets/sass/_variable.sass"
@import "@/assets/sass/_mixin.sass"

footer
	width: 100%
	background-color: #fff
	.container
		display: flex
		justify-content: space-between
		align-items: center
		padding: 2.22em 7em
		max-width: $wrapper_width
		margin: auto
		@include screen(1680px)
			display: block
		@include screen(996px)
			padding: 2.22em
			padding-bottom: 7em
		@include screen(768px)
			padding: 2.25em 1em
			padding-bottom: 6em
		p
			font-size: .7em
			color: #000
			line-height: normal
			@include screen(1680px)
				text-align: center
				padding-bottom: 1em
			@include screen(996px)
				text-align: left
				font-size: 1em
				letter-spacing: .04em
			@include screen(768px)
				border-bottom: 1px solid $dark_gray
				padding-bottom: 1.15em
		.link-box
			display: flex
			align-items: center
			@include screen(1680px)
				flex-wrap: wrap
		ul.link-box
			display: flex
			justify-content: center
			margin-right: 2em
			@include screen(1680px)
				width: 100%
				display: block
				text-align: center
				margin: auto
				margin-bottom: 1em
			@include screen(996px)
				text-align: left
			li
				margin-left: 1.8em
				position: relative
				@include screen(1680px)
					margin-left: 0
					padding: .5em 0
				@include screen(996px)
					border-bottom: 1px solid $dark_gray
					padding: 1em 0
				&:not(:last-of-type)
					&::before
						content: ''
						width: 1px
						height: .7em
						background-color: #000
						position: absolute
						top: 50%
						right: -1em
						transform: translateY(-50%)
						@include screen(1680px)
							content: none
				a
					font-size: .7em
					color: #000
					letter-spacing: normal
					line-height: normal
					@include screen(1680px)
						font-size: 2.14
		ul.social-box
			display: flex
			@include screen(1680px)
				width: 100%
				justify-content: center
			@include screen(996px)
				justify-content: flex-start
			li
				a
					width: 1.8em
					height: 1.8em
					margin: 0 .5em
					@include screen(996px)
						width: 1.625em
						margin: 0
						margin-right: .5em
					&.fb
						@include bg("@/assets/images/ic_fb.svg",contain)
					&.ig
						@include bg("@/assets/images/ic_ig.svg",contain)
					&.yt
						@include bg("@/assets/images/ic_yt.svg",contain)
					&.line
						@include bg("@/assets/images/ic_line.svg",contain)
