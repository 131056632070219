@import "@/assets/sass/_variable.sass"
@import "@/assets/sass/_mixin.sass"
.compare_fixed
	position: fixed
	right: 0
	top: 50%
	transform: translateY(-0%)
	z-index: 100
	@include screen(1400px)
		right: 1em
		top: auto
		bottom: 5.5em
		transform: translateY(0)
	>a.btn-compare
		display: none
		z-index: 100
		@include screen(1400px)
			display: block
			width: 2.55em
			height: 2.55em
			@include bg("@/assets/images/ic_car.svg",1.55em .965em)
			background-color: #fff
			box-shadow: 3px 3px 16px 0 rgba(0, 0, 0, 0.33)
			border-radius: 9999px
		span.count
			width: 1.66em
			height: 1.66em
			position: absolute
			font-size: .6em
			color: #fff
			border-radius: 9999px
			background-color: #c43535
			top: 0
			right: -.5em
			text-align: center
			line-height: 1.66em
	&.normalType
		.container
			@include screen(1400px)
				display: none
	&.collectionType
		@include screen(1400px)
			background-color: #fff
			border-top-left-radius: .4em
			border-top-right-radius: .4em
			box-shadow: 0 -.5em .75em 0 rgba(0, 0, 0, 0.45)
			width: 100%
			left: 0
			right: auto
			top: auto
			bottom: 0
		.container
			@include screen(1400px)
				box-shadow: none
				margin: auto
				max-width: 428px
				border-radius: 0
				width: 100%
				flex-direction: row
				padding: 1.7em 1em 1em 1em
				box-sizing: border-box
				ul
					display: flex
					margin-bottom: 0
					margin-right: auto
					li
						position: relative
						border-bottom: none
						margin-right: .5em
						padding: 0
						.pic
							margin-right: 0
							@include screen(1400px)
								width: 6em
							@include screen(768px)
								width: 3.75em
						.txt
							@include screen(1400px)
								display: none
						a.btn-delete
							position: absolute
							height: 1.45em
							width: 1.45em
							top: -.75em
							right: -.2em
							opacity: 1
							@include bg("@/assets/images/ic_trash-white.svg",.85em .85em)
							background-color: #666
							border-radius: 9999px
							z-index: 10

				.btn-box
					a
						font-size: .9em
						width: 7.38em
						height: 2.77em

	.container
		width: 11.75em
		border-radius: .5em
		background-color: #fff
		box-shadow: -.25em .5em .75em 0 rgba(0, 0, 0, 0.1)
		padding: 1em 0
		display: flex
		flex-direction: column
		justify-content: center
		align-items: center
		ul
			margin-bottom: 1.5em
			li
				border-bottom: dashed .05em #bababa
				display: flex
				justify-content: flex-start
				align-items: center
				padding: .5em 0
				.pic
					width: 3em
					margin-right: 0.5em
					img
						border-radius: .15em
				.txt
					margin-right: 0.5em
					width: 4.6em
					display: flex
					flex-direction: column
					h2
						font-size: .8em
						color: #000
						font-weight: bold
						line-height: 1.33
					p
						font-size: .6em
						color: $dark_gray
				a.btn-delete
					width: 0.75em
					height: 0.75em
					opacity: .5
					margin-left: auto
					@include bg("@/assets/images/ic_trash.svg",contain)
		.btn-box
			a
				color: #fff
				width: 11.875em
				height: 2.5em
				font-size: .8em
				letter-spacing: .06em
				background-color: $dark_blue
				margin: auto
